"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getZrxTradeRate = void 0;
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const contracts_1 = require("@shapeshiftoss/contracts");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const helpers_1 = require("../../utils/helpers/helpers");
const fetchFromZrx_1 = require("../utils/fetchFromZrx");
const helpers_2 = require("../utils/helpers/helpers");
function getZrxTradeRate(input, assertGetEvmChainAdapter, isPermit2Enabled, assetsById, zrxBaseUrl) {
    if (!isPermit2Enabled)
        return _getZrxTradeRate(input, assertGetEvmChainAdapter, zrxBaseUrl);
    return _getZrxPermit2TradeRate(input, assertGetEvmChainAdapter, assetsById, zrxBaseUrl);
}
exports.getZrxTradeRate = getZrxTradeRate;
async function _getZrxTradeRate(input, assertGetEvmChainAdapter, zrxBaseUrl) {
    const { sellAsset, buyAsset, accountNumber, receiveAddress, affiliateBps, potentialAffiliateBps, chainId, supportsEIP1559, sellAmountIncludingProtocolFeesCryptoBaseUnit, } = input;
    const slippageTolerancePercentageDecimal = input.slippageTolerancePercentageDecimal ??
        (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Zrx);
    const sellAssetChainId = sellAsset.chainId;
    const buyAssetChainId = buyAsset.chainId;
    if (!(0, helpers_2.isSupportedChainId)(sellAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_2.isSupportedChainId)(buyAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (sellAssetChainId !== buyAssetChainId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `cross-chain not supported - both assets must be on chainId ${sellAsset.chainId}`,
            code: types_1.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    const maybeZrxPriceResponse = await (0, fetchFromZrx_1.fetchZrxPrice)({
        buyAsset,
        sellAsset,
        sellAmountIncludingProtocolFeesCryptoBaseUnit,
        // Cross-account not supported for ZRX
        sellAddress: receiveAddress,
        affiliateBps,
        slippageTolerancePercentageDecimal,
        zrxBaseUrl,
    });
    if (maybeZrxPriceResponse.isErr())
        return (0, monads_1.Err)(maybeZrxPriceResponse.unwrapErr());
    const zrxPriceResponse = maybeZrxPriceResponse.unwrap();
    const { buyAmount: buyAmountAfterFeesCryptoBaseUnit, grossBuyAmount: buyAmountBeforeFeesCryptoBaseUnit, price, allowanceTarget, gas, expectedSlippage, } = zrxPriceResponse;
    const useSellAmount = !!sellAmountIncludingProtocolFeesCryptoBaseUnit;
    const rate = useSellAmount ? price : (0, utils_1.bn)(1).div(price).toString();
    // 0x approvals are cheaper than trades, but we don't have dynamic quote data for them.
    // Instead, we use a hardcoded gasLimit estimate in place of the estimatedGas in the 0x quote response.
    try {
        const adapter = assertGetEvmChainAdapter(chainId);
        const { average } = await adapter.getGasFeeData();
        const networkFeeCryptoBaseUnit = chain_adapters_1.evm.calcNetworkFeeCryptoBaseUnit({
            ...average,
            supportsEIP1559: Boolean(supportsEIP1559),
            // add gas limit buffer to account for the fact we perform all of our validation on the trade quote estimations
            // which are inaccurate and not what we use for the tx to broadcast
            gasLimit: (0, utils_1.bnOrZero)(gas).times(1.2).toFixed(),
        });
        return (0, monads_1.Ok)({
            id: (0, uuid_1.v4)(),
            accountNumber: undefined,
            receiveAddress: undefined,
            potentialAffiliateBps,
            affiliateBps,
            // Slippage protection is only provided for specific pairs.
            // If slippage protection is not provided, assume a no slippage limit.
            // If slippage protection is provided, return the limit instead of the estimated slippage.
            // https://0x.org/docs/0x-swap-api/api-references/get-swap-v1-quote
            slippageTolerancePercentageDecimal: expectedSlippage
                ? slippageTolerancePercentageDecimal
                : undefined,
            rate,
            steps: [
                {
                    estimatedExecutionTimeMs: undefined,
                    allowanceContract: allowanceTarget,
                    buyAsset,
                    sellAsset,
                    accountNumber,
                    rate,
                    feeData: {
                        protocolFees: {},
                        networkFeeCryptoBaseUnit, // TODO(gomes): do we still want to handle L1 fee here for rates, since we leverage upstream fees calcs?
                    },
                    buyAmountBeforeFeesCryptoBaseUnit,
                    buyAmountAfterFeesCryptoBaseUnit,
                    sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    source: types_1.SwapperName.Zrx,
                },
            ],
        });
    }
    catch (err) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'failed to get fee data',
            cause: err,
            code: types_1.TradeQuoteError.NetworkFeeEstimationFailed,
        }));
    }
}
async function _getZrxPermit2TradeRate(input, _assertGetEvmChainAdapter, _assetsById, zrxBaseUrl) {
    const { sellAsset, buyAsset, accountNumber, receiveAddress, affiliateBps, potentialAffiliateBps, sellAmountIncludingProtocolFeesCryptoBaseUnit, } = input;
    const slippageTolerancePercentageDecimal = input.slippageTolerancePercentageDecimal ??
        (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Zrx);
    const sellAssetChainId = sellAsset.chainId;
    const buyAssetChainId = buyAsset.chainId;
    if (!(0, helpers_2.isSupportedChainId)(sellAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_2.isSupportedChainId)(buyAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (sellAssetChainId !== buyAssetChainId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `cross-chain not supported - both assets must be on chainId ${sellAsset.chainId}`,
            code: types_1.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    const maybeZrxPriceResponse = await (0, fetchFromZrx_1.fetchZrxPermit2Price)({
        buyAsset,
        sellAsset,
        sellAmountIncludingProtocolFeesCryptoBaseUnit,
        // Cross-account not supported for ZRX
        sellAddress: receiveAddress,
        affiliateBps,
        slippageTolerancePercentageDecimal,
        zrxBaseUrl,
    });
    if (maybeZrxPriceResponse.isErr())
        return (0, monads_1.Err)(maybeZrxPriceResponse.unwrapErr());
    const zrxPriceResponse = maybeZrxPriceResponse.unwrap();
    const { buyAmount: buyAmountAfterFeesCryptoBaseUnit, minBuyAmount: buyAmountBeforeFeesCryptoBaseUnit, totalNetworkFee, } = zrxPriceResponse;
    const rate = (0, utils_1.bnOrZero)(buyAmountAfterFeesCryptoBaseUnit)
        .div(sellAmountIncludingProtocolFeesCryptoBaseUnit)
        .toString();
    // 0x approvals are cheaper than trades, but we don't have dynamic quote data for them.
    // Instead, we use a hardcoded gasLimit estimate in place of the estimatedGas in the 0x quote response.
    const networkFeeCryptoBaseUnit = totalNetworkFee;
    return (0, monads_1.Ok)({
        id: (0, uuid_1.v4)(),
        accountNumber: undefined,
        receiveAddress: undefined,
        potentialAffiliateBps,
        affiliateBps,
        // Slippage protection is only provided for specific pairs.
        // If slippage protection is not provided, assume a no slippage limit.
        // If slippage protection is provided, return the limit instead of the estimated slippage.
        // https://0x.org/docs/0x-swap-api/api-references/get-swap-v1-quote
        slippageTolerancePercentageDecimal,
        rate,
        steps: [
            {
                estimatedExecutionTimeMs: undefined,
                // We don't care about this - this is a rate, and if we really wanted to, we know the permit2 allowance target
                allowanceContract: (0, helpers_1.isNativeEvmAsset)(sellAsset.assetId) ? undefined : contracts_1.PERMIT2_CONTRACT,
                buyAsset,
                sellAsset,
                accountNumber,
                rate,
                feeData: {
                    protocolFees: {},
                    networkFeeCryptoBaseUnit, // L1 fee added inside of evm.calcNetworkFeeCryptoBaseUnit
                },
                buyAmountBeforeFeesCryptoBaseUnit,
                buyAmountAfterFeesCryptoBaseUnit,
                sellAmountIncludingProtocolFeesCryptoBaseUnit,
                source: types_1.SwapperName.Zrx,
            },
        ],
    });
}
