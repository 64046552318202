"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchZrxPrice = exports.fetchZrxQuote = exports.fetchZrxPermit2Quote = exports.fetchZrxPermit2Price = void 0;
const contracts_1 = require("@shapeshiftoss/contracts");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const helpers_1 = require("../../utils/helpers/helpers");
const constants_2 = require("./constants");
const helpers_2 = require("./helpers/helpers");
const zrxService_1 = require("./zrxService");
const fetchFromZrx = async ({ quoteOrRate, buyAsset, sellAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit, sellAddress, affiliateBps, slippageTolerancePercentageDecimal, zrxBaseUrl, }) => {
    const baseUrl = (0, helpers_2.baseUrlFromChainId)(zrxBaseUrl, buyAsset.chainId);
    const zrxService = (0, zrxService_1.zrxServiceFactory)({ baseUrl });
    const maybeTreasuryAddress = (() => {
        try {
            return (0, helpers_1.getTreasuryAddressFromChainId)(buyAsset.chainId);
        }
        catch (err) { }
    })();
    // Rates are gotten using ZRX /swap/v1/price endpoint
    const endpoint = quoteOrRate === 'quote' ? 'quote' : 'price';
    // https://docs.0x.org/0x-swap-api/api-references/get-swap-v1-quote
    const maybeZrxPriceResponse = await zrxService.get(`/swap/v1/${endpoint}`, {
        params: {
            enableSlippageProtection: true,
            buyToken: (0, helpers_2.assetIdToZrxToken)(buyAsset.assetId),
            sellToken: (0, helpers_2.assetIdToZrxToken)(sellAsset.assetId),
            sellAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
            takerAddress: sellAddress,
            affiliateAddress: constants_2.AFFILIATE_ADDRESS,
            // Always skip validation, so that we can get a quote even if no wallet is connected
            skipValidation: true,
            slippagePercentage: slippageTolerancePercentageDecimal ??
                (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Zrx),
            ...(maybeTreasuryAddress && {
                feeRecipient: maybeTreasuryAddress,
                feeRecipientTradeSurplus: maybeTreasuryAddress,
                buyTokenPercentageFee: (0, utils_1.convertBasisPointsToDecimalPercentage)(affiliateBps).toNumber(),
            }),
        },
    });
    if (maybeZrxPriceResponse.isErr()) {
        return (0, monads_1.Err)(maybeZrxPriceResponse.unwrapErr());
    }
    return (0, monads_1.Ok)(maybeZrxPriceResponse.unwrap().data);
};
const fetchZrxPermit2Price = (args) => fetchFromZrxPermit2({
    quoteOrRate: 'rate',
    ...args,
});
exports.fetchZrxPermit2Price = fetchZrxPermit2Price;
const fetchZrxPermit2Quote = (args) => fetchFromZrxPermit2({
    quoteOrRate: 'quote',
    ...args,
});
exports.fetchZrxPermit2Quote = fetchZrxPermit2Quote;
const fetchFromZrxPermit2 = async ({ quoteOrRate, buyAsset, sellAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit, sellAddress, affiliateBps, slippageTolerancePercentageDecimal, zrxBaseUrl, }) => {
    const zrxService = (0, zrxService_1.zrxServiceFactory)({ baseUrl: zrxBaseUrl });
    const maybeTreasuryAddress = (() => {
        try {
            return (0, helpers_1.getTreasuryAddressFromChainId)(buyAsset.chainId);
        }
        catch (err) { }
    })();
    // Rates are gotten using ZRX /swap/permit2/price endpoint
    const endpoint = quoteOrRate === 'quote' ? 'quote' : 'price';
    // https://0x.org/docs/api#tag/Swap/operation/swap::permit2::getPrice
    // https://0x.org/docs/api#tag/Swap/operation/swap::permit2::getQuote
    const maybeZrxPriceResponse = await zrxService.get(`/swap/permit2/${endpoint}`, {
        params: {
            chainId: contracts_1.viemNetworkIdByChainId[sellAsset.chainId],
            buyToken: (0, helpers_2.assetIdToZrxToken)(buyAsset.assetId),
            sellToken: (0, helpers_2.assetIdToZrxToken)(sellAsset.assetId),
            sellAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
            taker: sellAddress,
            swapFeeBps: parseInt(affiliateBps),
            swapFeeToken: (0, helpers_2.assetIdToZrxToken)(buyAsset.assetId),
            slippageBps: (0, utils_1.convertDecimalPercentageToBasisPoints)(slippageTolerancePercentageDecimal).toNumber(),
            swapFeeRecipient: maybeTreasuryAddress,
            feeRecipientTradeSurplus: maybeTreasuryAddress, // Where trade surplus is sent
        },
    });
    if (maybeZrxPriceResponse.isErr()) {
        return (0, monads_1.Err)(maybeZrxPriceResponse.unwrapErr());
    }
    return (0, monads_1.Ok)(maybeZrxPriceResponse.unwrap().data);
};
const fetchZrxQuote = (input) => fetchFromZrx({
    quoteOrRate: 'quote',
    ...input,
});
exports.fetchZrxQuote = fetchZrxQuote;
const fetchZrxPrice = (input) => fetchFromZrx({
    quoteOrRate: 'rate',
    ...input,
});
exports.fetchZrxPrice = fetchZrxPrice;
