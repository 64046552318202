"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPortalsTradeRate = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const viem_1 = require("viem");
const __1 = require("../../..");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const helpers_1 = require("../../utils/helpers/helpers");
const constants_1 = require("../constants");
const fetchPortalsTradeOrder_1 = require("../utils/fetchPortalsTradeOrder");
const helpers_2 = require("../utils/helpers");
async function getPortalsTradeRate(input, _assertGetEvmChainAdapter, swapperConfig) {
    const { sellAsset, buyAsset, accountNumber, affiliateBps, potentialAffiliateBps, chainId, sellAmountIncludingProtocolFeesCryptoBaseUnit, } = input;
    const sellAssetChainId = sellAsset.chainId;
    const buyAssetChainId = buyAsset.chainId;
    if (!(0, helpers_2.isSupportedChainId)(sellAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_2.isSupportedChainId)(buyAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (sellAssetChainId !== buyAssetChainId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `cross-chain not supported - both assets must be on chainId ${sellAsset.chainId}`,
            code: types_1.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    try {
        if (!(0, helpers_2.isSupportedChainId)(chainId))
            throw new Error(`Unsupported chainId ${sellAsset.chainId}`);
        const portalsNetwork = constants_1.chainIdToPortalsNetwork[chainId];
        if (!portalsNetwork) {
            return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
                message: `unsupported ChainId`,
                code: types_1.TradeQuoteError.UnsupportedChain,
                details: { chainId: input.chainId },
            }));
        }
        const sellAssetAddress = (0, helpers_1.isNativeEvmAsset)(sellAsset.assetId)
            ? viem_1.zeroAddress
            : (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference;
        const buyAssetAddress = (0, helpers_1.isNativeEvmAsset)(buyAsset.assetId)
            ? viem_1.zeroAddress
            : (0, caip_1.fromAssetId)(buyAsset.assetId).assetReference;
        const inputToken = `${portalsNetwork}:${sellAssetAddress}`;
        const outputToken = `${portalsNetwork}:${buyAssetAddress}`;
        const userSlippageTolerancePercentageDecimalOrDefault = input.slippageTolerancePercentageDecimal
            ? Number(input.slippageTolerancePercentageDecimal)
            : undefined; // Use auto slippage if no user preference is provided
        const quoteEstimateResponse = await (0, fetchPortalsTradeOrder_1.fetchPortalsTradeEstimate)({
            inputToken,
            outputToken,
            inputAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
            slippageTolerancePercentage: userSlippageTolerancePercentageDecimalOrDefault
                ? userSlippageTolerancePercentageDecimalOrDefault * 100
                : (0, utils_1.bnOrZero)((0, __1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Portals))
                    .times(100)
                    .toNumber(),
            swapperConfig,
        });
        // Use the quote estimate endpoint to get a quote without a wallet
        const rate = (0, utils_2.getRate)({
            sellAmountCryptoBaseUnit: input.sellAmountIncludingProtocolFeesCryptoBaseUnit,
            buyAmountCryptoBaseUnit: quoteEstimateResponse?.context.outputAmount,
            sellAsset,
            buyAsset,
        });
        const allowanceContract = (0, helpers_2.getPortalsRouterAddressByChainId)(chainId);
        const tradeRate = {
            id: (0, uuid_1.v4)(),
            accountNumber,
            receiveAddress: undefined,
            affiliateBps,
            potentialAffiliateBps,
            rate,
            slippageTolerancePercentageDecimal: quoteEstimateResponse.context.slippageTolerancePercentage
                ? (0, utils_1.bn)(quoteEstimateResponse.context.slippageTolerancePercentage).div(100).toString()
                : undefined,
            steps: [
                {
                    estimatedExecutionTimeMs: undefined,
                    allowanceContract,
                    accountNumber,
                    rate,
                    buyAsset,
                    sellAsset,
                    buyAmountBeforeFeesCryptoBaseUnit: quoteEstimateResponse.minOutputAmount,
                    buyAmountAfterFeesCryptoBaseUnit: quoteEstimateResponse.context.outputAmount,
                    sellAmountIncludingProtocolFeesCryptoBaseUnit: input.sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    feeData: {
                        networkFeeCryptoBaseUnit: undefined,
                        // Protocol fees are always denominated in sell asset here
                        protocolFees: {},
                    },
                    source: types_1.SwapperName.Portals,
                },
            ],
        };
        return (0, monads_1.Ok)(tradeRate);
    }
    catch (err) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'failed to get Portals quote',
            cause: err,
            code: types_1.TradeQuoteError.NetworkFeeEstimationFailed,
        }));
    }
}
exports.getPortalsTradeRate = getPortalsTradeRate;
